import { useNavigate } from 'react-router-dom'

interface ServiceProps {
    img: string;
    label: string;
    className?: string;
    url?: string;
}

export const Service = (props: ServiceProps) => {
    const { img, label, className, url } = props
    const navigate = useNavigate()

    return (
        <div onClick={() => {
            url && navigate(url)
            window.scrollTo({ top: 0 })
        }} className='flex flex-col items-center cursor-pointer w-[25%] md:w-auto gap-2'>
            <img className={`w-[128px] h-[128px] ${className} mix-blend-multiply object-contain`} src={img} alt='' loading='lazy' />
            <div className='max-w-[5rem] text-center'>{label}</div>
        </div>
    )
}