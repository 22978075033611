import '../index.scss'

import Quality from 'assets/advantages/1.svg'
import Money from 'assets/advantages/4.svg'
import Simple from 'assets/advantages/5.svg'

export const AboutUs = () => (
    <div id='about' className='mt-16 pb-16 mb-16 bg-[#fff]'>
        <div className='max-w-7xl mx-auto px-4'>
            <h1 id='services' className='text-center text-5xl font-semibold text-[#1E2D3B] py-6'>3 bonnes raisons de passer par Europisol</h1>
            <div className='h-1 w-24 bg-[#e88e2e] mx-auto mt-4 mb-8' />
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-[80rem] justify-center'>
                <QualityBox svg={Simple} title='Simple comme bonjour' content="Nous nous occupons de tout, vite et bien : contrats d'énergie, devis, primes... Finie la paperasse ! Nous nous engageons à réaliser toutes les démarches administratives permettant de profiter des aides auxquelles vous êtes éligible, vous ne vous occupez de rien." />
                <QualityBox svg={Quality} title='Qualité' content='Crée en 2019, Europisol aide ses clients à obtenir un meilleur confort d’habitat tout en leur permettant de réaliser des économies. Nos presque 3000 clients professionnels et particuliers peuvent certifier de notre savoir faire et de notre sérieux.' />
                <QualityBox svg={Money} title="Jusqu'à 60% d'économie" content='Grâce à nos travaux, vous pouvez réaliser une économie de 60% par an. L’isolation par l’extérieur et des combles et plancher bas, l’installation de pompe à chaleur, de ballon thermodynamique, d’une VMC et de double vitrage.' />
            </div>
        </div>
    </div>
)

interface QualityBoxProps {
    title: string;
    content: string;
    svg: any;
}

const QualityBox = (props: QualityBoxProps) => {
    const { title, content, svg } = props

    return (
        <div>
            <div className='bg-[#F7F7F7] py-8 px-4 h-full'>
                <div className='flex justify-center'>
                    <img className='w-[57px]' src={svg} alt="" loading="lazy" />
                </div>
                <div className='text-center text-[1.25rem] font-semibold text-[#1E2D3B] mt-4'>{title}</div>
                <div className='text-center mt-4'>{content}</div>
            </div>
        </div>
    )
}