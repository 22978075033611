import { Service } from 'routes/home/components/service/Service'

import vmc from 'assets/vmc1.png'
import Pompe from 'assets/pompe1.svg'
import Poele from 'assets/poele1.svg'
import Isolation from 'assets/isolation.svg'
import Audit from 'assets/audit.svg'
import Comble from 'assets/comble.png'

export const Services = () => (
    <div className='flex flex-wrap gap-16 justify-center mt-4'>
        <Service img={Poele} label='Poêle à granulés' url='/pellet-stove' className='p-4' />
        <Service img={Isolation} label='Isolation' url='/isolation' />
        <Service img={Audit} className='p-2' label='Audit' url='/audit' />
        <Service img={Pompe} label='Pompe à chaleur' url='/pompe' />
        <Service img={vmc} label='VMC' url='/vmc' className='p-6' />
        <Service img={Comble} label='Combles aménagés' url='/combles' className='' />
    </div>
)