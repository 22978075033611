import { Contact } from 'routes/home/containers/contact'
import { Main } from 'routes/combles/containers/main'
import { WhyInstall } from 'routes/combles/containers/whyinstall'

export const Combles = () => {

    return (
        <div>
            <Main />
            <WhyInstall />
            <Contact />
        </div>
    )
}