import { Contact } from 'routes/home/containers/contact'
import { Main } from 'routes/isolation/containers/main'
import { WhyInstall } from 'routes/isolation/containers/whyinstall'
import { Advantages } from 'routes/isolation/containers/advantages'
import { Example } from './containers/example'

export const Isolation = () => {

    return (
        <div>
            <Main />
            <WhyInstall />
            <Advantages />
            <Example />
            <Contact gray />
        </div>
    )
}