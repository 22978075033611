import france from 'assets/audit/france.png'

export const France = () => {

    return (
        <div className='bg-[#f7f7f7]'>
            <div className='max-w-7xl mx-auto px-4 py-6 flex gap-4 items-center'>
                <img className='mix-blend-multiply max-w-[10rem]' src={france} alt='france' />
                <div>À partir du 1er avril 2023, l'obligation de réaliser un audit énergétique concernera tout d'abord les habitations classées F ou G, les logements dits « passoires thermiques », dont la promesse de vente ou, à défaut, l'acte de vente, est signé à compter du 1er avril 2023, selon un décret daté du 9 août 2022.</div>
            </div>
        </div>
    )
}