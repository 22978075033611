import { Main } from './containers/main';
import { Services } from './containers/services';
import { OurServices } from './containers/ourservices';
import { AboutUs } from './containers/aboutus';
import { Certificates } from './containers/certificates';
import { Numbers } from './containers/numbers';

import { Contact } from './containers/contact';

export const Home = () => {
    return (
        <div>
            <Main />
            <Services />
            <OurServices />
            <AboutUs />
            <Certificates />
            <Numbers />
            <Contact />
        </div>
    )
}