import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper";

import certificat2 from 'assets/socotec.png'
import certificat3 from 'assets/certificat2.png'
import certificat4 from 'assets/certificat3.png'
import certificat5 from 'assets/certificat4.png'
import certificat6 from 'assets/certificat5.png'
import cee from 'assets/cee.webp'
import pg from 'assets/pg.png'
import qualibois from 'assets/qualibois.png'
import maprime from 'assets/maprime.png'
import opq from 'assets/opq.png'
import qualitenr from 'assets/qualitenr.png'
import domo from 'assets/domo.png'

import "swiper/css";

export const Certificates = () => {
    return (
        <div className='overflow-x-hidden select-none'>
            <Swiper
                className='translate-x-12 md:translate-x-24 xl:translate-x-44'
                slidesPerView={2}
                breakpoints={{ 768: { slidesPerView: 4 } }}
                loop={true}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay]}
            >
                <SwiperSlide>
                    <img className='w-[15%] aspect-[1/2] object-contain' src={certificat2} alt="" loading="lazy" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={certificat3} alt="" loading="lazy" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={certificat4} alt="" loading="lazy" />
                </SwiperSlide>
                <SwiperSlide>
                    <img className='max-w-[70%] md:max-w-[28%]' src={certificat5} alt="" loading="lazy" />
                </SwiperSlide>
                <SwiperSlide>
                    <img className='max-w-[70%] md:max-w-[28%]' src={certificat6} alt="" loading="lazy" />
                </SwiperSlide>
                <SwiperSlide>
                    <img className='max-w-[70%] md:max-w-[28%]' src={cee} alt="" loading="lazy" />
                </SwiperSlide>
                <SwiperSlide>
                    <img className='max-w-[70%] md:max-w-[40%]' src={pg} alt="" loading="lazy" />
                </SwiperSlide>
                <SwiperSlide>
                    <img className='max-w-[50%] md:max-w-[19%]' src={qualibois} alt="" loading="lazy" />
                </SwiperSlide>
                <SwiperSlide>
                    <img className='max-w-[50%] md:max-w-[19%]' src={maprime} alt="" loading="lazy" />
                </SwiperSlide>
                <SwiperSlide>
                    <img className='max-w-[50%] md:max-w-[19%]' src={opq} alt="" loading="lazy" />
                </SwiperSlide>
                <SwiperSlide>
                    <img className='max-w-[50%] md:max-w-[19%]' src={qualitenr} alt="" loading="lazy" />
                </SwiperSlide>
                <SwiperSlide>
                    <img className='max-w-[50%] md:max-w-[19%]' src={domo} alt="" loading="lazy" />
                </SwiperSlide>
            </Swiper>
        </div>
    )
}