import whyinstall from 'assets/combles/whyinstall.svg'

export const WhyInstall = () => {

    return (
        <div className='max-w-7xl mx-auto px-4 text-[#1E2D3B] mt-16 mb-16'>
            <div className='flex flex-col md:flex-row gap-4 items-center'>
                <div>
                    <h1 className='text-5xl text-[#2A7C9D] max-w-3xl'>Pourquoi aménager ses combles ?</h1>
                    <div className='md:ml-8 mt-8 max-w-md'>Aménager vos combles fait prendre de la valeur à votre bien immobilier. En effet, une fois les combles aménagés, votre maison est plus grande, mieux isolée et dispose aussi de pièces lumineuses que vous pouvez valoriser lors de la revente.</div>
                <div className='md:ml-8 mt-2 max-w-md'>Les travaux d’aménagement des combles sont aussi l’occasion d’améliorer l’isolation. Cela vous permettra de réaliser des économies d’énergie non négligeables. En effet, une grande partie des déperditions de chaleur se font par le toit ; en l’occurrence, elles peuvent atteindre 30%.</div>
                </div>
                <img className='md:max-w-[50%] mix-blend-multiply' src={whyinstall} alt='why' />
            </div>
        </div>
    )
}