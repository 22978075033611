import CountUp from 'react-countup';
import '../index.scss'

export const Numbers = () => {
    return (
        <div>
            <div className='bg-[#F7F7F7] numbers-grid justify-center items-center gap-16 pt-16 pb-8 text-[#1E2D3B]'>
                <div className='flex flex-col items-center'>
                    <div className='mb-6 invisible'>1</div>
                    <div className='text-7xl md:text-4xl lg:text-4xl xl:text-5xl 2xl:text-7xl font-bold'><CountUp separator=' ' end={32000} duration={window.innerWidth >= 904 || window.innerWidth <= 767 ? 1 : 0} enableScrollSpy />m²</div>
                    <div className='text-xl mt-6'>d'isolation réalisés</div>
                </div>
                <div className='hidden md:block w-[1px] h-16 bg-black' />
                <div className='flex flex-col items-center'>
                    <div className='text-xl mb-6'>Jusqu'à</div>
                    <div className='text-7xl md:text-4xl lg:text-4xl xl:text-5xl 2xl:text-7xl font-bold'><CountUp separator=' ' end={60} duration={window.innerWidth >= 904 || window.innerWidth <= 767 ? 1 : 0} enableScrollSpy />%</div>
                    <div className='text-xl mt-6'>d'économie</div>
                </div>
                <div className='hidden md:block w-[1px] h-16 bg-black' />
                <div className='flex flex-col items-center'>
                    <div className='text-xl mb-6'>Plus-value de </div>
                    <div className='text-7xl md:text-4xl lg:text-4xl xl:text-5xl 2xl:text-7xl font-bold'>+<CountUp separator=' ' end={19.7} decimals={1} decimal=',' duration={window.innerWidth >= 904 || window.innerWidth <= 767 ? 1 : 0} enableScrollSpy />%</div>
                    <div className='text-xl mt-6'>après travaux</div>
                </div>
            </div>
            <div className='bg-[#F7F7F7] numbers-grid-2 justify-center items-center gap-16 pb-16 text-[#1E2D3B]'>
                <div className='flex flex-col items-center'>
                    <div className='text-7xl md:text-4xl lg:text-4xl xl:text-5xl 2xl:text-7xl font-bold'><CountUp separator=' ' end={1890} duration={window.innerWidth >= 904 || window.innerWidth <= 767 ? 1 : 0} enableScrollSpy /></div>
                    <div className='text-xl mt-6'>Pompes à chaleur installées</div>
                </div>
                <div className='hidden md:block w-[1px] h-16 bg-black' />
                <div className='flex flex-col items-center'>
                    <div className='text-7xl md:text-4xl lg:text-4xl xl:text-5xl 2xl:text-7xl font-bold'><CountUp separator=' ' end={390} duration={window.innerWidth >= 904 || window.innerWidth <= 767 ? 1 : 0} enableScrollSpy /></div>
                    <div className='text-xl mt-6'>VMC installées</div>
                </div>
            </div>
        </div>
    )
}