export const Types = () => {

    return (
        <div className='bg-[#f7f7f7] py-16 text-[#1E2D3B]'>
            <div className='max-w-7xl mx-auto px-4'>
                <div className='text-center text-4xl pb-8 font-semibold'>Deux types de ventilation existent:</div>
                <div className='flex flex-col md:flex-row justify-around gap-4 text-center'>
                    <div>
                        <div className='text-2xl'>la VMC simple flux:</div>
                        <div className='max-w-md'>La VMC simple flux fonctionne en continu. Un moteur extrait l’air présent dans les pièces humides comme les toilettes, la cuisine et la salle de bain via des bouches d’extraction. Elle achemine l’air vicié à l’extérieur via des tuyaux conçus pour cet usage. Il s’agit d’un moteur à hélice, ou turbine, qui aspire l’air d’un côté et le recrache de l’autre.</div>
                    </div>
                    <div>
                        <div className='text-2xl'>la VMC double flux:</div>
                        <div className='max-w-md'>Comme elle fonctionne en continu, la VMC simple flux entraîne 20% de déperditions de chaleur. La VMC double flux permet de pallier cet inconvénient, car elle est dotée d’un récupérateur de chaleur. Ainsi, elle récupère la chaleur de l’air vicié pour la transmettre à l’air neuf, ce qui vous permet de faire jusqu’à 15% d’économies sur votre facture de chauffage.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}