import { useNavigate } from 'react-router-dom'

export const Footer = () => {
    const navigate = useNavigate()

    return (
        <div className='bg-[#1E2D3B] text-white'>
            <div className='max-w-7xl mx-auto py-16 px-16 text-sm text-center'>
                <span className='mt-8'>© 2022 Europisol. Tous droits réservés. </span>
                <span onClick={() => {
                    navigate('/mentions')
                    window.scrollTo({ top: 0 })
                }} className='cursor-pointer underline'>Mentions légales</span>
                <div className='flex justify-center gap-1'>
                    Réalisé par
                    <a href='mailto:sergiu.loghin2003@gmail.com' className='underline decoration-1 cursor-pointer'>Sergiu Loghin</a>
                </div>
            </div>
        </div>
    )
}