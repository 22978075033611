import schema from '../../../assets/pompe/advantages.webp'

export const AdvantagesContainer = () => {

    return (
        <div className='bg-[#F7F7F7] py-16 px-4'>
            <div className='max-w-7xl mx-auto'>
                <div className='flex items-center flex-col lg:flex-row px-4'>
                    <img src={schema} alt="" />
                    <div className='lg:max-w-md text-[#1E2D3B]'>
                        <div className='text-5xl text-[#2A7C9D] max-w-lg mb-6'>Les bénéfices:</div>

                        <div>● Énergie verte :</div>
                        <div className='ml-4 mb-2'>○ Utilisation de 80% d’énergies naturelles donc complètements gratuites</div>

                        <div>● Consommation d’énergie fiable :</div>
                        <div className='ml-4'>○ Peu d’électricité</div>
                        <div className='ml-4 mb-2'>○ Réduction des factures d’électricité</div>

                        <div>● Des solutions de chauffage multiples :</div>
                        <div className='ml-4 mb-2'>○ Chauffage qui répond à tous les besoins</div>

                        <div>● Usage confortable</div>
                        <div className='ml-4'>○ Aucune odeur</div>
                        <div className='ml-4 mb-2'>○ Monter rapidement la chaleur et la température de l’eau</div>

                        <div>● Dispositif éligible aux primes énergies</div>
                        <div className='ml-4'>○ Taux de TVA 5.5%</div>
                        <div className='ml-4'>○ Récupération d’une partie de la somme investie pour l’achat et l’installation</div>
                    </div>
                </div>
            </div>
        </div>
    )
}