import schema from 'assets/pelletstove/schema.webp'
import home from 'assets/pelletstove/home.webp'

export const SchemaContainer = () => {
    return (
        <div className='max-w-7xl mx-auto mt-16 px-4'>
            <div className='flex flex-col md:flex-row justify-center items-center lg:justify-between'>
                <div className='text-center md:text-start'>
                    <h1 className='text-5xl text-[#8A6A40] max-w-lg'>Pourquoi installer un poêle à granulés ?</h1>
                    <div className='md:ml-8 mt-8 max-w-md text-[#1E2D3B] px-2'>Les poêles à granulés sont conçus pour chauffer rapidement et efficacement à partir d’une énergie renouvelable, les granulés de bois (ou « pellets »). Ce combustible, sans colle ni additif, une fois chargé dans le poêle, est transporté vers la chambre de combustion grâce à un système de vis sans fin, qui en contrôle la vitesse et la quantité en fonction de la puissance de chauffage désirée. Le feu est allumé à l’aide d’une résistance électrique et un ventilateur apporte la bonne quantité d’oxygène nécessaire à la combustion. La chaleur est dégagée par convection naturelle ou grâce à un autre ventilateur.</div>
                    <img className='hidden md:block max-w-[60%] float-right' src={home} alt="" />
                </div>
                <img className='max-w-[60%] mt-4 md:mt-0 md:max-w-[40%]' src={schema} alt="" />
            </div>
        </div>
    )
}