import quality from 'assets/pompe/quality.png'

export const QualityContainer = () => {
    return (
        <div className='bg-white'>
            <div className='max-w-7xl w-full mx-auto'>
                <div className='flex gap-8 text-[#1E2D3B] items-center justify-center flex-col md:flex-row px-4 py-4'>
                    <div className='max-w-2xl'>
                        <div className='text-5xl flex flex-col lg:flex-row gap-2 font-bold mb-6'>Qualifié <div className='text-[#2A7C9D]'>RGE QUALI PAC</div></div>
                        <div className='mb-4'>Qualit’EnR a pour objectif de mettre en avant la fiabilité, le professionnalisme et le savoir-faire de différentes filières, afin de garantir les exigences et les critères de qualité des chantiers.</div>
                        <div className='flex'>
                            <div>Un artisan labellisé QualiPac est alors la promesse d’un travail qualitatif, et d’un haut respect des délais et du cahier des charges. Ainsi, le client bénéficie d’une installation de sa pompe à chaleur conforme aux normes en vigueur, en plus d’une excellente solution pour diminuer sa consommation quotidienne d’énergie. </div>
                            <img className='hidden md:block lg:hidden max-w-[250px] -translate-y-20 mix-blend-multiply' src={quality} alt="" />
                        </div>
                    </div>
                    <img className='xs:block md:hidden lg:block max-w-[250px] lg:max-w-full' src={quality} alt="" />
                </div>
            </div>
        </div>
    )
}