import { useNavigate } from 'react-router-dom';

interface OurServiceProps {
    img: string;
    right: boolean;
    title: string;
    description: string;
    url?: string;
    resize?: boolean;
    className?: string;
}

export const OurService = (props: OurServiceProps) => {
    const { img, title, description, right, url, resize } = props
    const navigate = useNavigate()

    return (
        <>
            {right ? (
                <div className='bg-[#F7F7F7] w-full flex flex-col items-center text-center md:text-start md:flex-row justify-around mt-16 py-4'>
                    <img className={`object-contain mix-blend-multiply w-[300px] className`} src={img} alt="" loading="lazy" />
                    <div className='flex flex-col items-center justify-center gap-4 py-8'>
                        <div className='text-4xl font-semibold text-[#1E2D3B] max-w-sm'>{title}</div>
                        <div className='max-w-sm'>{description}</div>
                        <div onClick={() => {
                            url && navigate(url)
                            window.scrollTo({ top: 0 })
                        }} className='border-[3px] border-[#8A6A40] py-2 px-4 inline-block rounded-3xl text-[#8A6A40] cursor-pointer hover:bg-[#8A6A40] hover:text-white'>En savoir plus</div>
                    </div>
                </div>
            ) : (
                <div className='bg-[#F7F7F7] w-full flex flex-col items-center text-center md:text-start md:flex-row justify-around mt-16 py-4'>
                    <div className='flex flex-col items-center justify-center gap-4 py-8'>
                        <div className='text-4xl font-semibold text-[#1E2D3B] max-w-sm'>{title}</div>
                        <div className='max-w-sm'>{description}</div>
                        <div onClick={() => {
                            url && navigate(url)
                            window.scrollTo({ top: 0 })
                        }} className='border-[3px] border-[#8A6A40] py-2 px-4 inline-block rounded-3xl text-[#8A6A40] cursor-pointer hover:bg-[#8A6A40] hover:text-white'>En savoir plus</div>
                    </div>
                    <img className={`object-contain mix-blend-multiply ${resize ? 'w-[250px]' : 'w-[300px]'}`} src={img} alt="" loading="lazy" />
                </div>
            )}
        </>
    )
}