export const Advantages = () => {

    return (
        <div className='bg-[#F7F7F7] py-16'>
            <div className='max-w-7xl mx-auto'>
                <div className='text-center text-4xl pb-8 text-[#1E2D3B] font-semibold'>Les avantages :</div>
                <div className='flex justify-center gap-8 text-2xl font-semibold text-[#1E2D3B] px-4 flex-col md:flex-row'>
                    <div className='max-w-md'>
                        <div className='text-[#8A6A40] mb-2'>● Une isolation thermique pour réduire sa facture d’énergie</div>
                        <div>● Réduire les nuisances sonores grâce à l’isolation des combles</div>
                    </div>
                    <div className='max-w-md'>
                        <div className='text-[#8A6A40] mb-2'>● L’isolation des combles, un geste écologique</div>
                        <div>● Isoler pour valoriser son logement </div>
                        <div className='text-[#8A6A40] mt-2'>● Une isolation des combles protège des nuisibles </div>
                    </div>
                </div>
            </div>
        </div>
    )
}