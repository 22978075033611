import why from 'assets/isolation/why.svg'

export const WhyInstall = () => {

    return (
        <div className='max-w-7xl mx-auto px-4 text-[#1E2D3B] mt-16 mb-16'>
            <div className='flex flex-col md:flex-row gap-4 items-center'>
                <div>
                    <h1 className='text-5xl text-[#2A7C9D] max-w-3xl'>Pourquoi isoler thermiquement l'extérieur ?</h1>
                    <div className='md:ml-8 mt-8 max-w-md'>Bien isoler un bâtiment permet de réduire sa consommation d'énergie et, de fait, de diminuer les émissions de gaz à effet de serre. L'isolation thermique d'un bâtiment contribue ainsi à la lutte contre le réchauffement climatique et à la préservation des ressources.</div>
                </div>
                <img className='max-w-[40%]' src={why} alt='why' />
            </div>
        </div>
    )
}