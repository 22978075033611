import { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { Burger, Transition } from '@mantine/core';
import { FiMail, FiPhoneCall } from 'react-icons/fi'

import logo from 'assets/logo.png'
import './Navbar.scss'

export const Navbar = () => {
    const navigate = useNavigate()
    const [opened, setOpened] = useState(false)

    return (
        <div className={`fixed top-0 w-full ${opened ? 'bg-[#F7F7F7]' : 'bg-white'} p-4 flex justify-between lg:justify-center items-center md:gap-8 lg:gap-16 xl:gap-24 z-50`}>
            <div className='md:w-[400px]'>
                <img onClick={() => {
                    navigate('/')
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                }} className='max-w-[100px] cursor-pointer' src={logo} alt='' />
            </div>
            <Burger onClick={() => {
                setOpened(!opened)
            }} opened={opened} size='lg' className='lg:hidden' title='test' />
            <div className='hidden lg:flex gap-8 lg:gap-8 xl:gap-16 text-[#1E2D3B]'>
                <Links label='ACCUEIL' url='/home' href='home' />
                <Links label='À PROPOS' url='/about' href='about' />
                <Links label='SERVICES' url='/services' href='services' />
                <Links label='CONTACT' url='/contact' href='contact' />
            </div>
            <div className='hidden lg:flex flex-col gap-2 xl:flex-row xl:gap-8 whitespace-nowrap'>
                <div onClick={() => {
                    window.open('mailto:europisol.commercial@gmail.com?subject=SendMail&body=Bonjour, j\'ai une question.')
                }} className='flex items-center gap-2 text-[#1E2D3B] cursor-pointer'>
                    <FiMail size={36} />
                    <div className='text-[14px] xl:text-base'>europisol.commercial@gmail.com</div>
                </div>
                <a href='tel:0188838225' className='flex items-center gap-2 text-[#1E2D3B]'>
                    <FiPhoneCall size={36} className=' cursor-pointer' />
                    <div className='text-[14px] xl:text-base'>01 88 83 82 25</div>
                </a>
            </div>
            <div className={`${opened ? 'burger flex-col items-center h-screen overflow-hidden pt-24 gap-8 text-[#1E2D3B]' : 'hidden'}`}>
                <Transition mounted={opened} transition="slide-up" duration={400} timingFunction="ease">
                    {(styles) =>
                        <div style={styles} onClick={() => setOpened(false)}>
                            <Links opened={opened} label='ACCUEIL' url='/' href='home' />
                        </div>}
                </Transition>
                <Transition mounted={opened} transition="slide-up" duration={400} timingFunction="ease">
                    {(styles) =>
                        <div style={styles} onClick={() => setOpened(false)}>
                            <Links opened={opened} label='À PROPOS' url='about' href='about' />
                        </div>}
                </Transition>
                <Transition mounted={opened} transition="slide-up" duration={400} timingFunction="ease">
                    {(styles) =>
                        <div style={styles} onClick={() => setOpened(false)}>
                            <Links opened={opened} label='SERVICES' url='/services' href='services' />
                        </div>}
                </Transition>
                <Transition mounted={opened} transition="slide-up" duration={400} timingFunction="ease">
                    {(styles) =>
                        <div style={styles} onClick={() => setOpened(false)}>
                            <Links opened={opened} label='CONTACT' url='/contact' href='services' />
                        </div>}
                </Transition>
                <div className='flex flex-col gap-8'>
                    <Transition mounted={opened} transition="slide-up" duration={400} timingFunction="ease">
                        {(styles) =>
                            <div style={styles}>
                                <div onClick={() => {
                                    window.open('mailto:europisol.commercial@gmail.com?subject=SendMail&body=Bonjour, j\'ai une question.')
                                }} className='flex flex-col items-center gap-2 text-[#1E2D3B] cursor-pointer'>
                                    <FiMail size={32} />
                                    <div>europisol.commercial@gmail.com</div>
                                </div>
                            </div>}
                    </Transition>
                    <Transition mounted={opened} transition="slide-up" duration={400} timingFunction="ease">
                        {(styles) =>
                            <div style={styles}>
                                <a href='tel:0188838225' className='flex flex-col items-center gap-2 text-[#1E2D3B]'>
                                    <FiPhoneCall size={32} className=' cursor-pointer' />
                                    <div>01 88 83 82 25</div>
                                </a>
                            </div>}
                    </Transition>
                </div>
            </div>
        </div>
    )
}

interface LinksProps {
    label: string;
    url: string;
    href: 'home' | 'services' | 'about' | 'contact';
    opened?: boolean;
}

const Links = (props: LinksProps) => {
    const { label, url, href, opened } = props
    const currentUrl = window.location.pathname
    const navigate = useNavigate()

    const heavyCondition = () => currentUrl === url && 'text-[#e88e2e]'

    return (
        <div onClick={() => {
            navigate(url)
            url === '/contact' && window.scrollTo({ top: 0 })
            if (href !== 'home') {
                setTimeout(() => {
                    const doc = document.getElementById(href)
                    doc && window.scrollTo({ top: doc.getBoundingClientRect().top + window.pageYOffset - 100, behavior: 'smooth' })
                }, 500)
            }
        }} className={`${opened && 'text-xl'} cursor-pointer hover:text-[#e88e2e] font-bold ${heavyCondition()} whitespace-nowrap`}>
            {label}
        </div>
    )
}