import main from 'assets/vmc/vmcdouble.jpeg'

export const WhyInstall = () => {

    return (
        <div className='max-w-7xl mx-auto px-4 text-[#1E2D3B] mt-16 mb-16'>
            <div className='flex flex-col md:flex-row gap-4 items-center'>
                <div>
                    <div className='text-5xl text-[#2A7C9D] max-w-3xl'>Pourquoi installer une VMC ?</div>
                    <div className='md:ml-8 mt-8 max-w-md'>L’air intérieur de nos logements est plus pollué que l’air extérieur. En effet, plusieurs sources de pollution sont présentes dans les murs, les sols, le mobilier et les produits d’entretien.</div>
                    <div className='md:ml-8 mt-2 max-w-md'>Par ailleurs, l’humidité s’accumule dans certaines pièces (cuisine, salle de bain, toilettes). Enfin, certains facteurs ne permettent pas une aération quotidienne suffisante : absence de fenêtres, température extérieure trop basse, ouvertures qui donnent sur un espace extérieur trop étroit pour être efficace.</div>
                    <div className='md:ml-8 mt-2 max-w-md'>Une VMC simple flux, qu'elle soit autoréglable ou hygroréglable, ne fait qu'extraire l'air vicié d'une habitation (l'humidité créée dans une cuisine, les salles d'eau…). La VMC double flux se charge en plus d'introduire l'air neuf d'une maison en récupérant chaleur ou fraîcheur issue de l'extraction de l'air vicié.</div>
                    <div className='md:ml-8 mt-2 max-w-md'>La VMC double flux est particulièrement recommandée lorsqu'il n'est pas possible d'ouvrir les fenêtres sur l'extérieur du fait de nuisances sonores ou olfactives ou lorsqu'il existe un risque d'intrusion. Ce système de ventilation est idéal lorsque le logement se situe dans un environnement extérieur bruyant.</div>
                </div>
                <img className='md:max-w-[50%] mix-blend-multiply' src={main} alt='why' />
            </div>
        </div>
    )
}