import { Button, Select, Textarea, TextInput } from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form';
import { sendMail } from 'api/sendMail';
import { FcCheckmark } from 'react-icons/fc'
import { useState } from 'react';

import * as yup from 'yup'

interface ContactProps {
    gray?: boolean;
}

export const Contact = (props: ContactProps) => {
    const { gray } = props

    const [mailSent, setMailSent] = useState(false)

    const schema = yup.object().shape({
        name: yup.string().min(2, 'Nom incorrect!'),
        phone: yup.string().matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, 'Numéro invalide!'),
        code: yup.string().min(1, 'Code postal invalide!'),
        objet: yup.string().min(1, 'Code postal invalide!'),
        email: yup.string().matches(/^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, 'Email invalide!'),
        message: yup.string().min(1, 'Message invalide!')
    });

    const form = useForm({
        schema: yupResolver(schema),
        initialValues: {
            name: '',
            phone: '',
            code: '',
            objet: null,
            email: '',
            message: ''
        }
    })

    const handleSubmit = (values: any) => {
        sendMail(values)
        form.reset()
        setMailSent(true)
    }

    return (
        <>
            {mailSent ? (
                <div className='w-full flex items-center justify-center'>
                    <div className='text-[#1E2D3B] p-20 flex flex-col items-center justify-center'>
                        <FcCheckmark size={128} />
                        <div className='text-2xl'>La demande a été envoyée!</div>
                        <div className='lg max-w-lg text-center'>Votre demande sera prise en charge par la société Europisol vous allez avoir un retour d’ici 24h.</div>
                    </div>
                </div>
            ) : (
                <div className={`${gray && 'bg-[#F7F7F7]'}`}>
                    <div className='max-w-7xl mx-auto pb-12 px-4'>
                        <div className='text-center text-5xl font-semibold text-[#1E2D3B] pt-12'>N'HÉSITEZ PAS À NOUS CONTACTER</div>
                        <div className='h-1 w-24 bg-[#e88e2e] mx-auto mt-4 mb-8' />
                        <div className='max-w-4xl mx-auto'>
                            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                                <div className='grid md:grid-cols-2 gap-4'>
                                    <TextInput {...form.getInputProps('name')} label='Nom' placeholder='Jean' required />
                                    <TextInput {...form.getInputProps('phone')} label='Téléphone' placeholder='+33...' required type='number' />
                                    <TextInput {...form.getInputProps('code')} label='Code postal' placeholder='93700' required type='number' />
                                    <TextInput {...form.getInputProps('email')} label='Email' placeholder='jean@paris.fr' required type='email' />
                                    <Select {...form.getInputProps('objet')} data={[{ value: 'Demande de devis', label: 'Demande de devis' }, { value: 'Proposition de partenariat', label: 'Proposition de partenariat' }, { value: 'Information generale', label: 'Information generale' }, { value: 'Autre', label: 'Autre' }]} label='Objet de la demande' required />
                                    <Textarea {...form.getInputProps('message')} label='Votre message' placeholder="Bonjour, j'ai une question..." required />
                                </div>
                                <div className='flex justify-end'>
                                    <div>
                                        <Button type='submit' className='bg-[#1E2D3B] hover:bg-[#1E2D3B] mt-8 w-full'>Contactez-nous</Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}