import { AdvantagesContainer } from "./components/AdvantagesContainer"
import { MainContainer } from "./components/MainContainer"
import { QualityContainer } from "./components/QualityContainer"
import { SchemaContainer } from "./components/SchemaContainer"
import { Contact } from 'routes/home/containers/contact'

export const PompePage = () => {
    return (
        <div>
            <MainContainer />
            <SchemaContainer />
            <AdvantagesContainer />
            <QualityContainer />
            <Contact gray />
        </div>
    )
}