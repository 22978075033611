import schema from 'assets/pompe/schema.webp'

export const SchemaContainer = () => (
    <div className='max-w-7xl mx-auto mt-16 pb-8'>
        <div className='flex justify-around flex-col lg:flex-row px-4'>
            <div>
                <h1 className='text-5xl text-[#2A7C9D] max-w-lg md:whitespace-nowrap'>Comment ça fonctionne ?</h1>
                <div className='md:ml-8 mt-8 lg:max-w-md text-[#1E2D3B]'>Dans un premier temps, nos techniciens se déplacent chez vous pour faire une pré visite technique qui nous permettra de récupérer les documents administratifs, mais aussi d'effectuer le dimensionnement et autres critères techniques. Par la suite, nos commerciaux prennent le dossier en charge afin d'évaluer vos besoins pour monter votre devis (gratuit).</div>
                <div className='flex'>
                    <div>
                        <div className='md:ml-8 mt-8 max-w-md text-[#1E2D3B]'>La pompe à chaleur vous permet de générer de l'énergie en récupérant les calories présentent naturellement en extérieur et en les transformant grâce à un procédé technique (voir schéma). Ces calories viennent de l’air. La pompe à chaleur va alors transformer ces calories naturelles en énergie ce qui permet de chauffer votre habitat et produire de l'eau chaude.</div>
                        <div className='md:ml-8 mt-8 max-w-md text-[#1E2D3B]'>La pompe à chaleur vous permettra de réaliser d’importantes économies d'énergies grâce à sa capacité de récupération des calories extérieures naturelles et par-dessus tout votre niveau de pollution sera réduit considérablement.</div>
                        <div className='md:ml-8 mt-8 max-w-md text-[#1E2D3B]'>La pompe à chaleur puise les calories dans l'eau et l'air afin de produire de la chaleur. Elle utilise des ressources propres, gratuites et renouvelables. Une pompe chaleur permet de générer des économies sur la facture annuelle de chauffage.</div>
                    </div>
                    <img className='hidden md:block lg:hidden max-w-[40%]' src={schema} alt="" />
                </div>
            </div>
            <img className='lg:max-w-[40%] mx-auto xs:block md:hidden lg:block' src={schema} alt="" />
        </div>
    </div>
)