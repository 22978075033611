import main from 'assets/audit/main.jpeg'

export const WhyInstall = () => {

    return (
        <div className='max-w-7xl mx-auto px-4 text-[#1E2D3B] mt-16 mb-16'>
            <div className='flex flex-col md:flex-row gap-4 items-center'>
                <div>
                    <h1 className='text-5xl text-[#2A7C9D] max-w-3xl'>Qu'est-ce qu'un audit énergétique ?</h1>
                    <div className='md:ml-8 mt-8 max-w-md'>L'audit énergétique est un diagnostic qui permet de connaître précisément les performances énergétiques de votre habitation. Il s'agit d'une cartographie complète de votre logement qui examine : </div>
                    <div className='flex flex-col md:ml-8 mt-2'>
                        <div>- la structure de l'habitation;</div>
                        <div>- l'état de son isolation thermique;</div>
                        <div>- sa consommation énergétique;</div>
                        <div>- le système de chauffage du logement;</div>
                        <div>- le système de production d'eau chaude sanitaire;</div>
                        <div>- la ventilation;</div>
                        <div>- l'éclairage;</div>
                    </div>
                </div>
                <img className='max-w-[50%]' src={main} alt='why' />
            </div>
        </div>
    )
}