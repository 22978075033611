import ReactDOM from 'react-dom/client';

import Routing from './routes/Routing';

import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<Routing />
);