import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { MainLayout } from 'layout/MainLayout'

import { Home } from 'routes/home/home'
import { ContactPage } from 'routes/contact/ContactPage'
import { PelletStovePage } from 'routes/pelletstove/PelletStovePage'
import { PompePage } from 'routes/pompe/PompePage'
import { Realizations } from 'routes/realizations/realizations'
import { Isolation } from './isolation/isolation'
import { Audit } from './audit/audit'
import { VMC } from './vmc/vmc'
import { Mentions } from './mentions/mentions'
import { Combles } from './combles/combles'

export default function Routing() {

    return (
        <BrowserRouter>
            <MainLayout>
                <Routes>
                    <Route path='*' element={<Home />} />
                    <Route path='/pellet-stove' element={<PelletStovePage />} />
                    <Route path='/pompe' element={<PompePage />} />
                    <Route path='/isolation' element={<Isolation />} />
                    <Route path='/audit' element={<Audit />} />
                    <Route path='/vmc' element={<VMC />} />
                    <Route path='/combles' element={<Combles />} />
                    <Route path='/contact' element={<ContactPage />} />
                    <Route path='/mentions' element={<Mentions />} />
                    <Route path='/realisations' element={<Realizations />} />
                </Routes>
            </MainLayout>
        </BrowserRouter>
    )
}
