import { OurService } from 'routes/home/components/ourservice/OurService'

import vmc from 'assets/vmc.png'
import Pompe from 'assets/pompe.jpg'
import Poele from 'assets/poele.svg'
import Isolation from 'assets/isolation1.svg'
import Audit from 'assets/audit1.svg'
import Combles from 'assets/combles2.png'

export const OurServices = () => (
    <div className='max-w-7xl mx-auto mt-6'>
        <div id='services' className='text-center text-7xl font-semibold text-[#1E2D3B]'>Nos services</div>
        <div className='h-1 w-24 bg-[#e88e2e] mx-auto mt-4' />
        <OurService
            right={true}
            url='/pellet-stove'
            img={Poele}
            title='Poêle à granulés'
            description={`Le poêle est un appareil servant au chauffage domestique. Simple d’installation et d’utilisation, ce poêle adapte sa puissance selon la température souhaitée. La technologie des modèles actuels permettent un rendement de plus de 90,7%, permettant jusqu’à 71% d’économies d’énergies.`}
        />
        <OurService
            right={false}
            url='/isolation'
            img={Isolation}
            title="Isolation thermique par l'extérieur"
            description={`L’isolation thermique par l’exterieur (ITE) permet de réduire les pertes de chaleurs en plus de rafraichir votre façade. Ainsi les  consommations énergétiques de votre foyer vont grandement diminuer (jusqu’à 40%) réduisant ainsi vos factures et la pollution engendrée par votre chauffage. Gagnez entre 4 et 6 degrés en isolant toute la maison.`}
        />
        <OurService
            right={true}
            url='/pompe'
            img={Pompe}
            title="Pompes à chaleur"
            description={`La pompe à chaleur air-eau est un équipement de production de chauffage et d'eau chaude sanitaire. Cet appareil génère de 45 à 75 % d'économies d'énergie par rapport à une chaudière traditionnelle. Le fonctionnement de la pompe à chaleur air-eau s'appuie sur l'énergie renouvelable.`}
        />
        <OurService
            right={false}
            url='/audit'
            img={Audit}
            title="Audit énergétique"
            description={`L'audit énergétique est un diagnostic détaillé des performances énergétiques de votre habitation. Cela permettra de vérifier la qualité de l'isolation, le bon fonctionnement de l'installation du chauffage, système d'eau chaude, système de ventilation et enfin la bonne utilisation de vos différents équipements.`}
        />
        <OurService
            right={true}
            url='/vmc'
            img={vmc}
            title="VMC"
            description={`Une VMC double flux thermodynamique permet d'assurer la ventilation des pièces, le chauffage en hiver et la climatisation en été. Elle assure le renouvellement de l'air et vous fait gagner en confort thermique. En effet, un air ambiant plus sec est plus facile à chauffer. Ainsi, la facture de chauffage baisse.`}
        />
        <OurService
            right={false}
            url='/combles'
            img={Combles}
            title="Combles aménagés"
            description={`Les travaux d’aménagement des combles sont aussi l’occasion d’améliorer l’isolation. Cela vous permettra de réaliser des économies d’énergie non négligeables. En effet, une grande partie des déperditions de chaleur se font par le toit ; en l’occurrence, elles peuvent atteindre 30%.`}
        />
    </div>
)