import { useNavigate } from 'react-router-dom'

export const AdvantagesContainer = () => {
    const navigate = useNavigate()

    return (
        <div className='bg-[#F7F7F7] mt-16 pb-8'>
            <div className='max-w-7xl mx-auto'>
                <div className='text-center text-4xl pt-8 pb-8 text-[#1E2D3B] font-semibold'>Les avantages :</div>
                <div className='flex justify-center gap-8 text-2xl font-semibold text-[#1E2D3B] px-4 flex-col md:flex-row'>
                    <div className='max-w-md'>
                        <div className='text-[#8A6A40]'>● Jusqu’à  70% d’économies sur le chauffage</div>
                        <div>● Combustible le moins coûteux et le + écologique</div>
                        <div className='text-[#8A6A40]'>● Un confort toute l’année</div>
                    </div>
                    <div>
                        <div>● Programmable</div>
                        <div className='text-[#8A6A40]'>● 0 Émission de CO2</div>
                        <div>● Norme NF 14785</div>
                        <div className='text-[#8A6A40]'>● Éligible aux aides de l’état</div>
                        <div>● Un logement revalorisé</div>
                    </div>
                </div>
                <div className='flex justify-center'>
                    <div onClick={() => {
                        navigate('/contact')
                        window.scrollTo({ top: 0 })
                    }} className='border py-4 px-16 inline-block border-[#8A6A40] rounded-[120px] text-[#8A6A40] text-md cursor-pointer bg-white hover:bg-[#8A6A40] hover:text-white mt-16'>Obtenir un devis</div>
                </div>
            </div>
        </div>
    )
}