import example from 'assets/isolation/example.png'
import blk3d from 'assets/isolation/blk3d.png'

export const Example = () => {

    return (
        <div className='max-w-7xl mx-auto py-16 text-[#1E2D3B] px-4'>
            <div id='services' className='text-center text-5xl font-semibold text-[#1E2D3B]'>HAUTE PRÉCISION</div>
            <div className='h-1 w-24 bg-[#e88e2e] mx-auto mt-4' />
            <div className='mt-6 text-center'>Haute précision, nous ne travaillons qu'avec des techniques professionnelles.</div>
            <div className='flex justify-center'>
                <img className='max-w-[20rem] py-4' src={example} alt='example' />
            </div>
            <div className='flex gap-4 flex-col md:flex-row justify-center items-center'>
                <div className='max-w-md'>Le BLK3D combine une caméra stéréo étalonnée, des capteurs de mesure, des logiciels et des fonctions de détection des bords embarquées, afin que les mesures à l'image aient une précision professionnelle. Chaque image capturée est un enregistrement de mesures en 3D précises et complètes.</div>
                <img className='max-w-[10rem]' src={blk3d} alt='example' />
            </div>
        </div>
    )
}