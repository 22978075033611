import map from 'assets/map.jpg'

import './RealizationsPage.scss'
import { Contact } from 'routes/home/containers/contact';
import { useState } from 'react';

import isolationPrev from 'assets/realizations/3.jpg'
import pompePrev from 'assets/realizations/pompe/1.jpeg'
import vmcPrev from 'assets/vmcPrev.jpeg'


export const Realizations = () => {

    const [isolationOpened, setIsolationOpened] = useState(false)
    const [pompeOpened, setPompeOpened] = useState(false)
    const [vmcOpened, setVmcOpened] = useState(false)

    function importAll(r) {
        return r.keys().reduce((acc, item) => {
            if (isNaN(parseInt(item.replace('./', '')[0]))) {
                acc[item.replace('./', '')] = r(item);
            }
            return acc;
        }, {});
    }

    const isolationImages = importAll(require.context('../../assets/realizations', false, /\.(png|jpe?g|svg)$/));
    const pompeImages = importAll(require.context('../../assets/realizations/pompe', false, /\.(png|jpe?g|svg)$/));
    const vmcImages = importAll(require.context('../../assets/realizations/vmc', false, /\.(png|jpe?g|svg)$/));

    return (
        <div className='bg-[#F7F7F7] mt-[100px] text-[#1E2D3B]'>
            <div className='pt-8 max-w-7xl mx-auto px-4'>
                <div id='services' className='text-center text-6xl font-semibold'>Nos réalisations</div>
                <div className='h-1 w-24 bg-[#e88e2e] mx-auto mt-4 mb-4' />
                <img className='max-w-[70%] mx-auto' src={map} alt="" />
                <div className='flex flex-wrap justify-center gap-16 py-16'>
                    <div className='relative flex'>
                        <img className='w-[256px] h-[256px] object-cover' src={isolationPrev} alt="" />
                        <div className='absolute bg-[#20344F] inset-0 opacity-25 max-w-[256px] max-h-[256px] z-10'></div>
                        <div className='absolute top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%] text-white z-50 text-center' onClick={() => {
                            setIsolationOpened(prev => !prev)
                            setPompeOpened(false)
                            setVmcOpened(false)

                            if (!isolationOpened) {
                                setTimeout(() => {
                                    const doc = document.getElementById('isolation')
                                    doc && window.scrollTo({ top: doc.getBoundingClientRect().top + window.pageYOffset - 100, behavior: 'smooth' })
                                }, 500)
                            }
                        }}>
                            <div className=' text-white flex flex-col items-center justify-center h-full cursor-pointer hover:animate-pulse'>
                                <div className='text-3xl'>ISOLATION</div>
                                <div className='whitespace-nowrap'>Cliquez pour en voir plus</div>
                            </div>
                        </div>
                    </div>

                    <div className='relative flex'>
                        <img className='w-[256px] h-[256px] object-cover' src={pompePrev} alt="" />
                        <div className='absolute bg-[#20344F] inset-0 opacity-25 max-w-[256px] max-h-[256px] z-10'></div>
                        <div className='absolute top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%] text-white z-50 text-center' onClick={() => {
                            setPompeOpened(prev => !prev)
                            setIsolationOpened(false)
                            setVmcOpened(false)

                            if (!pompeOpened) {
                                setTimeout(() => {
                                    const doc = document.getElementById('pompe')
                                    doc && window.scrollTo({ top: doc.getBoundingClientRect().top + window.pageYOffset - 100, behavior: 'smooth' })
                                }, 500)
                            }
                        }}>
                            <div className=' text-white flex flex-col items-center justify-center h-full cursor-pointer hover:animate-pulse'>
                                <div className='text-3xl'>POMPES À CHALEUR</div>
                                <div className='whitespace-nowrap'>Cliquez pour en voir plus</div>
                            </div>
                        </div>
                    </div>

                    <div className='relative flex'>
                        <img className='w-[256px] h-[256px] object-cover' src={pompePrev} alt="" />
                        <div className='absolute bg-[#20344F] inset-0 opacity-25 max-w-[256px] max-h-[256px] z-10'></div>
                        <div className='absolute top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%] text-white z-50 text-center' onClick={() => {
                            setVmcOpened(prev => !prev)
                            setIsolationOpened(false)
                            setPompeOpened(false)

                            if (!pompeOpened) {
                                setTimeout(() => {
                                    const doc = document.getElementById('pompe')
                                    doc && window.scrollTo({ top: doc.getBoundingClientRect().top + window.pageYOffset - 100, behavior: 'smooth' })
                                }, 500)
                            }
                        }}>
                            <div className=' text-white flex flex-col items-center justify-center h-full cursor-pointer hover:animate-pulse'>
                                <div className='text-3xl'>POMPES À CHALEUR</div>
                                <div className='whitespace-nowrap'>Cliquez pour en voir plus</div>
                            </div>
                        </div>
                    </div>
                </div>

                {isolationOpened && (
                    <div id='isolation' className='flex flex-wrap gap-8 mt-2 justify-center mb-8'>
                        {Object.keys(isolationImages).map((img, i) => <img className='w-[256px] h-[256px] object-cover' key={i} src={isolationImages[img]} alt='' loading='lasy' />)}
                    </div>
                )}
                {pompeOpened && (
                    <div id='pompe' className='flex flex-wrap gap-8 mt-2 justify-center mb-8'>
                        {Object.keys(pompeImages).map((img, i) => <img className='w-[256px] h-[256px] object-cover' key={i} src={pompeImages[img]} alt='' loading='lasy' />)}
                    </div>
                )}
            </div>
            <div className='bg-white'>
                <Contact />
            </div>
        </div>
    )
}