import { AdvantagesContainer } from "./components/AdvantagesContainer"
import { SchemaContainer } from "./components/SchemaContainer"
import { MainContainer } from "./components/MainContainer"
import { Contact } from 'routes/home/containers/contact'
import { WhatsApp } from "routes/home/components/WhatsApp"

export const PelletStovePage = () => {
    return (
        <div>
            <WhatsApp />
            <MainContainer />
            <SchemaContainer />
            <AdvantagesContainer />
            <Contact />
        </div>
    )
}