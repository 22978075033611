import '../PompePage.scss'

export const MainContainer = () => {
    return (
        <div className='mt-[50px] background-img h-[369px] w-full'>
            <div className='mt-[50px]'>
                
            </div>
        </div>
    )
}