import { useNavigate } from 'react-router-dom'

import '../index.scss'

export const Main = () => {
    const navigate = useNavigate()

    return (
        <div id='home' className='background-image h-screen'>
            <div className='bg-black bg-opacity-70 h-full'>
                <div className='max-w-7xl mx-auto flex flex-col justify-center h-full px-4'>
                    <div className='xl:max-w-xl mx-auto md:mx-0 text-center xl:text-start text-5xl md:text-6xl font-bold'>
                        <div className='text-[#e88e2e]'>La rénovation énergétique</div>
                    </div>
                    <div className='flex justify-center xl:block'>
                        <div onClick={() => {
                            navigate('/realisations')
                        }} className='border p-4 inline-block rounded-3xl text-white text-xl cursor-pointer hover:bg-white hover:text-[#e88e2e] mt-16'>Nos réalisations</div>
                    </div>
                </div>
            </div>
        </div>
    )
}