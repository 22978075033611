import { ReactNode } from 'react'

import { Navbar } from './components/Navbar/Navbar';
import { Footer } from './components/Footer/Footer';
import { WhatsApp } from 'routes/home/components/WhatsApp';

interface MainLayoutProps {
    children: ReactNode;
}

export const MainLayout = ({ children }: MainLayoutProps) => {

    return (
        <div className='h-screen'>
            <Navbar />
            <main>
                <WhatsApp />
                {children}
            </main>
            <Footer />
        </div>
    )
}